/**
 * Author: Viktor Gorte
 * CopyRight: Viktor Gorte
 * Date: 29.05.2021
 */

import React from 'react';
import {Link} from "react-scroll";
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
	link: {
		cursor: "pointer",
		color: theme.palette.text.main
	},
	active: {
		fontWeight: "bold"
	}
}));

export const ScrollLink = ({
	                           to,
	                           label,
                           }) => {
	const classes = useStyles();
	
	return (
		<Link
			activeClass={classes.active}
			to={to}
			spy={true}
			smooth={true}
			duration={500}
			className={classes.link}
		>
			{label}
		</Link>
	);
};

