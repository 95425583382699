/**
 * Author: Viktor Gorte
 * CopyRight: Viktor Gorte
 * Date: 28.05.2021
 */

import React from 'react';
import {useLocation} from 'react-router-dom';
import {NavigationDemoPage} from "./NavigationDemoPage";
import {NavigationApiPage} from "./NavigationApiPage";

export const NavigationContainerContent = (props) => {
	
	const location = useLocation();
	
	const renderNavigationContent = () => {
		switch (location?.pathname) {
			case "/":
				return <NavigationDemoPage/>;
			case "/api":
				return <NavigationApiPage/>;
			default:
				return null;
		}
	};
	
	return (
		<nav>
			{
				location?.pathname &&
				renderNavigationContent()
			}
		</nav>
	);
};
