let endpoint = "https://geo.jockala.de"
const publicUrl = process.env.PUBLIC_URL + ""; //is replaced on buildtime

if (publicUrl.indexOf('http://dev.geo-services') === 0) {
    endpoint = 'http://dev.geo-services.jockala.de:8080';
}

const ap = {
    endpoint,
    apiKey: 'map-demo-key',
}

export const getApiProps = props => ap;

//| Service     | Path
//|:------------|:------------------------------------------------
//| Info        | /info/
//| Fonts       | /fonts/{fontstack}/{start}-{end}.pbf
//| Styles      | /maps/{mapId}/style.json
//| Sprites     | /maps/{mapId}/sprite\[@2x].{format}
//| Static Maps | /maps/{mapId}/static/*
//| Vectortiles | /vector/{dataId}/{z}/{x}/{y}.pdf
//| Rastertiles | /raster/{mapId}/{TileMatrixSet}/{z}/{x}/{y}.png
//| WMTS        | /wmts/\[1.0.0/WMTSCapabilities.xml]
//| WMS         | /wms/

export const getRasterZXYTemplate = ({endpoint, apiKey}, mapId) =>
    `${endpoint}/raster/${mapId}/GoogleMapsCompatible/{z}/{x}/{y}.png?apiKey=${apiKey}`;

export const getStyleTemplate = ({endpoint, apiKey}, mapId) =>
    `${endpoint}/maps/${mapId}/style.json?apiKey=${apiKey}`;

export const getStaticMapUrl = ({endpoint, apiKey}, mapId, params) => {
    const {longitude, latitude, zoom, width, height} = params;
    return `${endpoint}/maps/${mapId}/static/${longitude},${latitude},${zoom}/${width}x${height}.png?apiKey=${apiKey}`;
}

export const getStaticMapAreaBasedUrl = ({endpoint, apiKey}, mapId, params) => {
    const {minx, miny, maxx, maxy, width, height} = params;
    return `${endpoint}/maps/${mapId}/static/${minx},${miny},${maxx},${maxy}/${width}x${height}.png?apiKey=${apiKey}`;
}

export const getInfoServiceUrl = ({endpoint, apiKey}) =>
    `${endpoint}/info/?apiKey=${apiKey}`;

export const fetchInfo = ({endpoint, apiKey}) =>
    fetch(getInfoServiceUrl({endpoint, apiKey}))
        .then((response) => response.json())
        .catch((reason => console.error(reason)));