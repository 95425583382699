/**
 * Author: Viktor Gorte
 * CopyRight: Viktor Gorte
 * Date: 30.05.2021
 */

import React from 'react';
import {getApiProps, getStaticMapUrl} from '../../util/geoServiceUtil';

export const StaticMap = ({mapId, ...rest}) => {
  const apiProps = getApiProps();

  const staticMapUrl = getStaticMapUrl(apiProps, mapId, rest);

  return (<img src={staticMapUrl} alt="static map"/>);
};
