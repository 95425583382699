/**
 * Author: Viktor Gorte
 * CopyRight: Viktor Gorte
 * Date: 29.05.2021
 */

import React from 'react';
import {Element} from 'react-scroll';
import {Divider, Grid, Link, makeStyles, Typography} from '@material-ui/core';
import {HighlightedCodeBlock} from './apiComponents/HighlightedCodeBlock';
import {ApiEndpointBlock} from './apiComponents/ApiEndpointBlock';
import {
    getRasterZXYTemplate,
    getStaticMapAreaBasedUrl,
    getStaticMapUrl,
    getStyleTemplate,
} from '../util/geoServiceUtil';
import {API_KEY_PLACEHOLDER, DEFAULT_EXAMPLE_ENDPOINT, MAP_ID_PLACEHOLDER} from '../constants/constants';

const useStyles = makeStyles((theme) => ({
    elementsContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        paddingRight: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    element: {
        display: 'flex',
        width: '100%',
        marginBottom: theme.spacing(4),
    },
    elementsDivider: {
        marginBottom: theme.spacing(4),
        height: 2,
        backgroundColor: theme.palette.primary.main,
    },
    codeBlockDivider: {
        marginBottom: theme.spacing(4),
        marginTop: theme.spacing(4),
    },
}));

export const ApiPageSections = () => {

    const classes = useStyles();
    const apiParamsPlaceholder = {endpoint: DEFAULT_EXAMPLE_ENDPOINT, apiKey: API_KEY_PLACEHOLDER};

    const RASTER_MAP = getRasterZXYTemplate(apiParamsPlaceholder, MAP_ID_PLACEHOLDER);

    const STATIC_MAP_PARAMS = {
        longitude: '{lng}',
        latitude: '{lat}',
        zoom: '{zoom}',
        height: '{height}',
        width: '{width}'
    }
    const STATIC_MAP_PARAMS_AREA_BASED = {
        minx: '{minx}',
        miny: '{miny}',
        maxx: '{maxx}',
        maxy: '{maxy}',
        height: '{height}',
        width: '{width}'
    }
    const STATIC_MAP = getStaticMapUrl(
        apiParamsPlaceholder,
        MAP_ID_PLACEHOLDER,
        STATIC_MAP_PARAMS);

    const STATIC_MAP_AREA_BASED = getStaticMapAreaBasedUrl(
        apiParamsPlaceholder,
        MAP_ID_PLACEHOLDER,
        STATIC_MAP_PARAMS_AREA_BASED);

    const STATIC_MAP_PATH_VARIABLES = `
    lng: Längengrad des Kartenzentrums
    lat: Breitengrad des Kartenzentrums
    zoom: Zoomstufe
    mapId: Name des Kartenstyles
    height: Höhe der Static map in px
    width: Breite der Static map in px
  `;
    const STATIC_MAP_AREA_BASED_PATH_VARIABLES = `
    minx: Untere linke Ecke X-Koordinate
    miny: Untere linke Ecke Y-Koordinate
    maxx: Obere rechte Ecke X-Koordinate
    maxy: Obere rechte Ecke Y-Koordinate
    mapId: Name des Kartenstyles
    height: Höhe der Static map in px
    width: Breite der Static map in px
  `;
    const STATIC_MAP_QUERY_PARAMS = `
    markers={lng},{lat}[|{lat},{lng}] (jeweils ein lng/lat Paar pro Marker)
      colors={color} (red, green, blue, yellow)
    
    marker=center
    
    path={lng},{lat}[|{lat},{lng}] (jeweils ein lng/lat Paar pro Knoten des Pfades)
      fill={color} (Farbe der Füllung geschlossener Formen)
      stroke={color} (Farbe der Außengrenzen geschlossener Formen)
      width={number} (Breite der Linien)
      padding={number} (Prozentuales Padding der Linie (0.1 = 10%)
      latlng (Koordinatenpaare in Längengrad/Breitengrad anstatt lng/lat)
    
    apiKey={dein-api-key}
    
    Beispiel: ?markers=7.617508,51.961912|7.634174,51.965619&apiKey=dein-api-key
    Beispiel: ?marker=center&apiKey=dein-api-key
    Beispiel: ?markers=7.617508,51.961912|7.634174,51.965619&colors=red,green&apiKey=dein-api-key
  `

    const VECTOR_TILES = getStyleTemplate(apiParamsPlaceholder, MAP_ID_PLACEHOLDER);
    const WMS = `${DEFAULT_EXAMPLE_ENDPOINT}/wms?apiKey=${API_KEY_PLACEHOLDER}`
    const WMTS = `${DEFAULT_EXAMPLE_ENDPOINT}/wmts?apiKey=${API_KEY_PLACEHOLDER}`

    return (
        <div className={classes.elementsContainer}>
            <Element name={'raster-Map'}>
                <div className={classes.element}>
                    <Grid container>
                        <Grid item xs={12} md={4}>
                            <Typography variant="h3" component="h2">
                                Raster Karte
                            </Typography>

                            <Typography variant="body1" component="p" paragraph>
                                Mit Raster Karten kannst du eine Library wie
                                z.B. <Link to="https://leafletjs.com/" target="_blank">Leaflet</Link> nutzen und den
                                Api-Endpunkt direkt ins das Getting Started Beispiel eintragen.
                                Der Raster Endpunkt liefert PNGs für deine Karte im Koordinatenformat z/x/y.
                            </Typography>

                        </Grid>
                        <Grid item xs={12} md={8}>
                            <ApiEndpointBlock
                                label={'Beispiel'}
                                type="GET"
                                endPointString={RASTER_MAP}
                            />
                            <HighlightedCodeBlock
                                codeString="mapId: Name des Kartenstyles"
                                language="none"
                                label="Pfadvariablen"
                            />
                            <HighlightedCodeBlock
                                codeString="apiKey={dein-api-key}"
                                language="none"
                                label="Query Parameter"
                            />
                        </Grid>
                    </Grid>
                </div>
            </Element>

            <Divider className={classes.elementsDivider}/>

            <Element name={'static-map'}>
                <div className={classes.element}>
                    <Grid container>
                        <Grid item xs={12} md={4}>
                            <Typography variant="h3" component="h2">
                                Static Map
                            </Typography>

                            <Typography variant="body1" component="p" paragraph>
                                Mit dem Static Map Endpunkt bekommst du eine Karte als PNG Bild. Definiere die
                                Koordinaten des Zentrums
                                der Karte, die Zoomstufe und Marker auf der Karte.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <ApiEndpointBlock
                                label={'Beispiel'}
                                type="GET"
                                endPointString={STATIC_MAP}
                            />
                            <HighlightedCodeBlock
                                codeString={STATIC_MAP_PATH_VARIABLES}
                                label="Pfadvariablen"
                                language={'none'}
                            />
                            <ApiEndpointBlock
                                label={'Beispiel'}
                                type="GET"
                                endPointString={STATIC_MAP_AREA_BASED}
                            />
                            <HighlightedCodeBlock
                                codeString={STATIC_MAP_AREA_BASED_PATH_VARIABLES}
                                label="Pfadvariablen"
                                language={'none'}
                            />
                            <HighlightedCodeBlock
                                codeString={STATIC_MAP_QUERY_PARAMS}
                                label="Query Parameter"
                                language={'none'}
                            />
                        </Grid>
                    </Grid>
                </div>
            </Element>

            <Divider className={classes.elementsDivider}/>

            <Element name={'vector-tiles'}>
                <div className={classes.element}>
                    <Grid container>
                        <Grid item xs={12} md={4}>
                            <Typography variant="h3" component="h2">
                                Vektor Tiles
                            </Typography>

                            <Typography variant="body1" component="p" paragraph>
                                Mit Vektor Tiles bekommst du die Karten Daten als Vektordaten. Du kannst sie mit Plugins
                                with mapbox-gl für Leaflet verwenden.
                            </Typography>

                        </Grid>
                        <Grid item xs={12} md={8}>
                            <ApiEndpointBlock
                                label={'Beispiel'}
                                type="GET"
                                endPointString={VECTOR_TILES}
                            />
                            <HighlightedCodeBlock
                                codeString="mapId: Name des Kartenstyles"
                                language="none"
                                label="Pfadvariablen"
                            />
                            <HighlightedCodeBlock
                                codeString="apiKey={dein-api-key}"
                                language="none"
                                label="Query Parameter"
                            />
                        </Grid>
                    </Grid>
                </div>
            </Element>

            <Divider className={classes.elementsDivider}/>

            <Element name={'wms'}>
                <div className={classes.element}>
                    <Grid container>
                        <Grid item xs={12} md={4}>
                            <Typography variant="h3" component="h2">
                                WMS
                            </Typography>

                            <Typography variant="body1" component="p" paragraph>
                                Mit dem WMS (Web map service) kannst du Rasterkarten INSPIRE konform abrufen. Mit einer
                                Anfrage auf den GetCapabilities Endpunkt werden die unterstützten Ausgabeformate als
                                XML geliefert. Der GetMap Endpunkt liefert eine Rasterkarte mit denen im Request angegebenen
                                Parametern (siehe <Link href="https://www.ogc.org/standards/wms" target="_blank">OGC Standard</Link>).
                            </Typography>

                        </Grid>
                        <Grid item xs={12} md={8}>
                            <ApiEndpointBlock
                              label={'Beispiel'}
                              type="GET"
                              endPointString={WMS}
                            />
                        </Grid>
                    </Grid>
                </div>
            </Element>


            <Divider className={classes.elementsDivider}/>

            <Element name={'wmts'}>
                <div className={classes.element}>
                    <Grid container>
                        <Grid item xs={12} md={4}>
                            <Typography variant="h3" component="h2">
                                WMTS
                            </Typography>

                            <Typography variant="body1" component="p" paragraph>
                                Mit dem WMTS (Web map tile service) kannst du Kartenteile (Tiles) INSPIRE konform abrufen. Mit einer
                                Anfrage auf den GetCapabilities Endpunkt werden die unterstützten Koordinatensystemen und Daten als
                                XML geliefert. Der GetTile Endpunkt liefert eine Kartenkachel mit denen im Request angegebenen
                                Parametern (siehe <Link href="https://www.ogc.org/standards/wmts" target="_blank">OGC Standard</Link>).
                            </Typography>

                        </Grid>
                        <Grid item xs={12} md={8}>
                            <ApiEndpointBlock
                              label={'Beispiel'}
                              type="GET"
                              endPointString={WMTS}
                            />
                        </Grid>
                    </Grid>
                </div>
            </Element>
        </div>
    );
};
