import React from 'react';
import PropTypes from 'prop-types';
import {Button, Divider, Grid, makeStyles, Typography} from '@material-ui/core';
import {StaticMap} from './maps/StaticMap';

const useStyles = makeStyles((theme) => ({
  elementsDivider: {
    marginBottom: theme.spacing(2),
    height: 2,
    backgroundColor: theme.palette.primary.main,
  },
  buttons: {
    marginRight: theme.spacing(2),
  },
}));

export const MapInfo = ({mapInfo, onShowMap, index}) => {
  const classes = useStyles();
  const {name, id: mapId, version, url, tileJSON, description} = mapInfo;

  const _onShowMap = (mode) => {
    onShowMap(mapInfo, mode);
  };
  return (
    <>
      {
        index !== 0 && <Divider color="primary" className={classes.elementsDivider}/>
      }
      <Grid container justifyContent="space-between">
        <Grid item xs={12} sm={12} md={8}>
          <Typography variant="h2" component="h2">
            {name}
          </Typography>

          <Typography variant="body1" component="p" paragraph>
            {description}
          </Typography>
          <Typography variant="body1" component="p" paragraph>
            Id der Karte für Requests auf die API-Endpunkte (mapId): {mapId}
          </Typography>
          <Typography variant="body1" component="p">
            Version der Karte: {version}
          </Typography>

          <Button
            className={classes.buttons}
            variant="contained"
            color="primary"
            onClick={() => _onShowMap('vector')}
          >
            Vektor Karte ansehen
          </Button>

          <Button
            className={classes.buttons}
            variant="contained"
            color="primary"
            onClick={() => _onShowMap('raster')}
          >
            Raster Karte ansehen
          </Button>

          <Button
            className={classes.buttons}
            variant="contained"
            color="primary"
            onClick={() => _onShowMap('compare')}
          >
            Vergleich der Karten ansehen
          </Button>

        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <StaticMap mapId={mapId}
                     latitude={51.961996}
                     longitude={7.625109}
                     zoom={10}
                     width={256}
                     height={256}
          />
        </Grid>
      </Grid>
    </>
  );
};

MapInfo.propTypes = {
  mapInfo: PropTypes.object.isRequired,
  onShowMap: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
};