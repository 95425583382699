/**
 * Author: Viktor Gorte
 * CopyRight: Viktor Gorte
 * Date: 28.05.2021
 */

import React from 'react';
import {makeStyles, Typography} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container: {
    borderRadius: theme.roundness,
    backgroundColor: 'lightgrey',
    marginBottom: theme.spacing(4),
  },
  label: {
    marginLeft: theme.spacing(2),
    paddingTop: theme.spacing(2),
  },
  apiContainer: {
    marginTop: theme.spacing(1),
    backgroundColor: '#f3f1f1',
    borderBottomLeftRadius: theme.roundness,
    borderBottomRightRadius: theme.roundness,
  },
  endPoint: {
    color: 'white',
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(1),
    borderRadius: theme.roundness,
    marginRight: theme.spacing(2),
  },
  textPosition: {
    marginLeft: theme.spacing(10),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
}));

export const ApiEndpointBlock = ({endPointString = '', type = '', label = ''}) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Typography
        variant={'subtitle2'}
        component={'p'}
        className={classes.label}
      >
        {label}
      </Typography>

      <div className={classes.apiContainer}>
        <Typography
          className={classes.textPosition}
          variant={'body1'}
          component={'p'}
        >
          {
            type && <span className={classes.endPoint}>{type}</span>
          }

          {endPointString}
        </Typography>
      </div>
    </div>
  );
};
