/**
 * Author: Viktor Gorte
 * CopyRight: Viktor Gorte
 * Date: 28.05.2021
 */

import React from 'react';
import {Grid, Link, Typography} from '@material-ui/core';
import {ApiPageSections} from "../components/ApiPageSections";

export const ApiPage = (props) => {

	return (
		<Grid container>
			<Grid item xs={12}>
				<Typography variant={"h1"} component={"h1"}>
				API
			</Typography>
			</Grid>
			
			<Grid item xs={12}>
				<Typography variant={"subtitle1"} component={"h2"}>
					API Definition für die Jockala (UG) Karten.
				</Typography>
			</Grid>
			
			<Grid item xs={12}>
				<Typography variant="body1" component="p" paragraph>
				Um einen API-Key zu beantragen wende dich an <Link href="mailto:info@jockala.de">info@jockala.de</Link>.
			</Typography>
			</Grid>
			
			<Grid item xs={12}>
				<ApiPageSections/>
			</Grid>			
		</Grid>
	);
};
