/**
 * Author: Viktor Gorte
 * CopyRight: Viktor Gorte
 * Date: 28.05.2021
 */

import React from 'react';
import {Link as ExternalLink} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  noHover: {
    '&:hover': {
      textDecoration: 'none',
    },
  },
}));

export const NavigationDemoPage = () => {
  const classes = useStyles();

  return (
    <ul className="nav navbar-nav">
      <li>
        <ExternalLink
          className={classes.noHover}
          color="textPrimary"
          target="_blank"
          title="Impressum"
          href="https://jockala.de/kontakt/">
          Impressum
        </ExternalLink>
      </li>
    </ul>
  );
};
