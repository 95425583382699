/**
 * Author: Viktor Gorte
 * CopyRight: Viktor Gorte
 * Date: 28.05.2021
 */

import React from 'react';
import {Route, Switch, useHistory,} from "react-router-dom";
import {NavigationSideBar} from "../components/NavigationSideBar";
import {DemoPage} from "../pages/DemoPage";
import {ApiPage} from "../pages/ApiPage";
import { Grid } from '@material-ui/core';

export const Routes = () => {
	const history = useHistory();
	
	return (
		<Grid container>
			<Grid item xs={12}>
				<Switch>
					<Route exact path="/">
						<NavigationSideBar history={history} content={<DemoPage/>}/>
					</Route>
					<Route path="/api">
						<NavigationSideBar history={history} content={<ApiPage/>}/>
					</Route>
				</Switch>
			</Grid>
		</Grid>
	);
};
