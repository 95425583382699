/**
 * Author: Viktor Gorte
 * CopyRight: Viktor Gorte
 * Date: 28.05.2021
 */

import React, {useEffect, useState} from 'react';
import {Dialog, Typography} from '@material-ui/core';
import {fetchInfo, getApiProps} from '../util/geoServiceUtil';
import {VectorAndRasterMap} from '../components/VectorAndRasterMap';
import {MapInfo} from '../components/MapInfo';
import {LeafletMapVector} from '../components/maps/LeafletMapVector';
import {LeafletMapRaster} from '../components/maps/LeafletMapRaster';
import {AspectRatioBox} from '../components/AspectRatioBox';


export const DemoPage = () => {

  const apiProps = getApiProps();
  const [geoInfo, setGeoInfo] = useState(null);

  const [mapInfoForDetails, setMapInfoForDetails] = useState(null);
  const [mapMode, setMapMode] = useState(null);

  useEffect(() => {
    fetchInfo(apiProps).then(setGeoInfo);
  }, [apiProps]);

  const _onShowMap = (mapInfo, mode) => {
    setMapInfoForDetails(mapInfo);
    setMapMode(mode);
  };

  const _renderDialogContent = () => {
    const mapId = mapInfoForDetails && mapInfoForDetails.id;
    switch (mapMode) {
      case 'vector':
        return (
          <>
            <Typography variant="h3" component="h3">Vektor Karte</Typography>
            <AspectRatioBox ratio={2}>
              <LeafletMapVector mapId={mapId}/>
            </AspectRatioBox>
          </>
        );
      case 'raster':
        return (
          <>
            <Typography variant="h3" component="h3">Raster Karte</Typography>
            <AspectRatioBox ratio={2}>
              <LeafletMapRaster mapId={mapId}/>
            </AspectRatioBox>
          </>
        );
      case 'comparison':
        return <VectorAndRasterMap mapId={mapId}/>;
      default:
        return <VectorAndRasterMap mapId={mapId}/>;
    }
  };

  const maps = geoInfo && geoInfo.maps;

  return (
    <div>
      <Typography variant={'h1'} component={'h1'}>
        Maps
      </Typography>
      <div>
        {maps && maps.map((mapInfo, index) =>
          <MapInfo key={index} index={index} mapInfo={mapInfo} onShowMap={_onShowMap}/>,
        )}
      </div>
      <Dialog
        fullWidth
        maxWidth={'lg'}
        open={mapInfoForDetails != null}
        onClose={() => setMapInfoForDetails(null)}>
        {_renderDialogContent()}
      </Dialog>
    </div>
  );
};
