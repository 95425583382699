/**
 * Author: Viktor Gorte
 * CopyRight: Viktor Gorte
 * Date: 24.05.2021
 */

import React, {useState} from 'react';
import {useLocation} from 'react-router-dom';
import {AppBar, Button, ButtonGroup, Grid, makeStyles, Hidden, Drawer, CssBaseline, Toolbar, IconButton} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import logo from '../../images/jockala_logo.png';
import {NavigationContainerContent} from "../navigation/NavigationContainerContent";

const drawerWidth = 250;

const useStyles = makeStyles((theme) => ({
	buttonGroupPadding: {
		padding: theme.spacing(4)
	},
	buttonStyles: {
		color: theme.palette.text.main,
		borderColor: theme.palette.text.main
	},
	image: {
		maxWidth: 200,
	},
	root: {
		display: 'flex',
	  },
	  drawer: {
		[theme.breakpoints.up('sm')]: {
		  width: drawerWidth,
		  flexShrink: 0,
		},
	  },
	  appBar: {
		[theme.breakpoints.up('sm')]: {
		  width: `calc(100% - ${drawerWidth}px)`,
		  marginLeft: drawerWidth,
		},
	  },
	  menuButton: {
		marginRight: theme.spacing(2),
		[theme.breakpoints.up('sm')]: {
		  display: 'none',
		},
	  },
	  // necessary for content to be below app bar
	  toolbar: theme.mixins.toolbar,
	  drawerPaper: {
		width: drawerWidth,
	  },
	  content: {
		paddingTop: 60,
		flexGrow: 1,
		padding: theme.spacing(3),
	  },
}));


export const NavigationSideBar = ({history, content}) => {
	
	const location = useLocation()?.pathname;
	const classes = useStyles();

	const [mobileOpen, setMobileOpen] = useState(false);

	const handleDrawerToggle = () => {
	  setMobileOpen(!mobileOpen);
	};

	const drawer = <>
	{
	   history && location &&
			   <ButtonGroup fullWidth className={classes.buttonGroupPadding}>
				   <Button
					   onClick={() => history.push("/")}
					   color={location === "/" ? 'primary' : ''}
					   variant="contained"
				   >
					   DEMO
				   </Button>
				   
				   <Button
					   onClick={() => history.push("/api")}
					   color={location !== "/" ? 'primary' : ''}
					   variant="contained"
				   >
					   API
				   </Button>
			   </ButtonGroup>
		   }

		   <NavigationContainerContent/>
	   </>
	
	return (
		<div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <img src={logo} alt={"Jockala"} className={classes.image}/>
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer}>
        <Hidden smUp implementation="css">
          <Drawer
            variant="temporary"
            anchor="left"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <Grid container className={classes.content}>
        <div className={classes.toolbar} />
		<Grid item xs={12}>
			{content}
		</Grid>
      </Grid>
    </div>
	);
};
