/**
 * Author: Viktor Gorte
 * CopyRight: Viktor Gorte
 * Date: 29.05.2021
 */

import {createMuiTheme} from '@material-ui/core/styles';

export const theme = createMuiTheme({
	spacing: 4,
	roundness: 8,
	palette: {
		primary: {
			main: "#47b563",
		},
		secondary: {
			main: "#ED7D31",
		},
		
		text: {
			main: "#212121"
		}
	},
	typography: {
		allVariants: {
			color: "#212121"
		},
	},
});
