/**
 * Author: Viktor Gorte
 * CopyRight: Viktor Gorte
 * Date: 30.05.2021
 */

import React, {useEffect, useState} from 'react';
import * as L from "leaflet";
import {GestureHandling} from "leaflet-gesture-handling";
import "leaflet/dist/leaflet.css";
import "leaflet-gesture-handling/dist/leaflet-gesture-handling.css";
import "./leaflet-mapbox-gl";
import {makeStyles} from '@material-ui/core';
import {getApiProps, getStyleTemplate} from "../../util/geoServiceUtil";

const useStyles = makeStyles((theme) => ({
    map: {
        backgroundColor: "white",
        width: "100%",
        height: "100%"
    }
}));

let globalKey = 0;

export const LeafletMapVector = ({mapId}) => {
    const classes = useStyles();
    const [mapKey] = useState(globalKey++);
    const apiProps = getApiProps();

    useEffect(() => {

        if (mapId) {
            L.Map.addInitHook("addHandler", "gestureHandling", GestureHandling);
            var map = L.map(`map-vector-${mapKey}`, {
                center: [51.961996, 7.625109],
                zoom: 11,
                gestureHandling: true,
                attributionControl: false
            });

            var gl = L.mapboxGL({
                style: getStyleTemplate(apiProps, mapId),
                accessToken: undefined
            }).addTo(map);
        }

    }, [apiProps, mapId, mapKey]);

    return (<div id={`map-vector-${mapKey}`} className={classes.map}/>)
};
