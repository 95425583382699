import './App.css';

import {Routes} from "./js/navigation/Routes";

const App = (props) => {
	return (
		<Routes/>
	);
};

export default App;
