import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import App from './App';
import {theme} from "./styles/Theme";
import {BrowserRouter} from "react-router-dom";
import {CssBaseline, ThemeProvider} from '@material-ui/core';
import './index.css';

ReactDOM.render(
	<React.StrictMode>
		<CssBaseline/>
		<ThemeProvider theme={theme}>
			<BrowserRouter basename="/ui">
				<App/>
			</BrowserRouter>
		</ThemeProvider>
	</React.StrictMode>,
	document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
