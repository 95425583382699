import React from 'react';
import {Grid, makeStyles, Typography} from '@material-ui/core';
import {LeafletMapRaster} from "./maps/LeafletMapRaster";
import {LeafletMapVector} from "./maps/LeafletMapVector";
import {AspectRatioBox} from './AspectRatioBox';

const useStyles = makeStyles((theme) => ({
    container: {
        borderRadius: theme.roundness,
        backgroundColor: "lightgrey"
    },
    map: {
        width: "100%",
        height: "100%"
    },
}));

export const VectorAndRasterMap = ({mapId}) => {
    const classes = useStyles();
    return (
        <Grid container item xs={12} spacing={2}>
            <Grid item md={6} sm={12}>
                <Typography variant="h3" component="h3">Vector Map</Typography>
                <AspectRatioBox>
                    <LeafletMapVector
                        className={classes.map}
                        key={mapId + 'vector'}
                        mapId={mapId}
                    />
                </AspectRatioBox>
            </Grid>
            <Grid item md={6} sm={12}>
                <Typography variant="h3" component="h3">Raster Map</Typography>
                <AspectRatioBox>
                    <LeafletMapRaster
                        className={classes.map}
                        key={mapId + 'raster'}
                        mapId={mapId}
                    />
                </AspectRatioBox>
            </Grid>
        </Grid>
    );
};
