/**
 * Author: Viktor Gorte
 * CopyRight: Viktor Gorte
 * Date: 28.05.2021
 */

import React from 'react';
import {ScrollLink} from "./ScrollLink";

export const NavigationApiPage = () => {
	return (
		<ul className="nav navbar-nav">
			<li>
				<ScrollLink label={"Raster Karte"} to={"raster-Map"}/>
			</li>
			<li>
				<ScrollLink label={"Static Map"} to={"static-map"}/>
			</li>
			<li>
				<ScrollLink label={"Vektor Tiles"} to={"vector-tiles"}/>
			</li>
			<li>
				<ScrollLink label={"WMS"} to={"wms"}/>
			</li>
			<li>
				<ScrollLink label={"WMTS"} to={"wmts"}/>
			</li>
		</ul>
	);
};
