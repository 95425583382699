/**
 * Author: Viktor Gorte
 * CopyRight: Viktor Gorte
 * Date: 28.05.2021
 */

import React, {useEffect} from 'react';
import Prism from 'prismjs';
import 'prismjs/themes/prism-tomorrow.css';
import {makeStyles, Typography} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container: {
    borderRadius: theme.roundness,
    backgroundColor: 'lightgrey',
  },
  label: {
    marginLeft: theme.spacing(2),
    paddingTop: theme.spacing(2),
  },
  codeBlockContainer: {
    marginTop: theme.spacing(1),
    backgroundColor: '#f3f1f1',
    borderBottomLeftRadius: theme.roundness,
    borderBottomRightRadius: theme.roundness,
  },
}));

export const HighlightedCodeBlock = ({codeString = '', language = 'js', label = ''}) => {
  const classes = useStyles();

  useEffect(() => {
    Prism.highlightAll();
  }, []);

  return (
    <div className={classes.container}>
      <Typography
        className={classes.label}
        variant={'subtitle2'}
        component={'p'}
      >
        {label}
      </Typography>
      <pre className={classes.codeBlockContainer}>
                 <code className={`language-${language}`}>{codeString}</code>
            </pre>
    </div>
  );
};
